<template>
  <div class="section" id="discount">
    <h2>{{ $t('modal.discount.amount') }}</h2>
    <input type="hidden" name="type" id="input-discount" value="">

    <ul>
      <li v-for="item in discount" :key="item.value"
          :class="{ 'act' : selected.discount === item.value}"
          @click="changeDiscount(item.value)"
      >{{ item.name }}
      </li>
      <li class="js-modal-link" data-target="#modal-choose-discount"
          :class="{ 'act' : selected.discount === 'another'}"
          @click="changeDiscount('another')"
      >{{ $t('modal.discount.another') }}<span v-show="anotherValue !== null">: {{anotherValue}} %</span>
      </li>
    </ul>
  </div>
</template>

<script>
  import {eventBus} from '@/main.js';

  export default {
    name: 'changeDiscount',
    data() {
      return {
        selected: {
          discount: 5,
        },
        discount: [
          {
            value: 5,
            name: '5%'
          },
          {
            value: 10,
            name: '10%'
          },
          {
            value: 50,
            name: '50%'
          },
        ],
        anotherValue: null,
      };
    },
    mounted() {
      eventBus.$on("onDiscountChose", discount => {
        this.selected.discount = 'another';
        this.anotherValue = discount;
        this.$emit('onChangeDiscount', discount);
      });
      eventBus.$on("onDiscountModalClose", () => {
        if(this.anotherValue === null) {
          this.selected.discount = this.discount[0].value;
        }
        this.$emit('onChangeDiscount', this.selected.discount);
      });
      this.changeDiscount(this.selected.discount)
    },
    methods: {
      changeDiscount(value) {
        this.selected.discount = value;
        if(this.selected.discount !== 'another') {
          this.anotherValue = null;
          this.$emit('onChangeDiscount', value);
        }
      },
    }
  };
</script>

<style scoped>

</style>
