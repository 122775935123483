<template>
  <div class="section" id="duration">
    <h2 v-if="itsFor === 'goods'">{{ $t('modal.duration.how-long') }}</h2>
    <h2 v-if="itsFor === 'special'">{{ $t('modal.duration.expire-date') }}</h2>
    <input type="hidden" name="duration" id="input-duration" value="">

    <ul>
      <li v-for="item in duration" :key="item.value"
          :class="{ 'act' : selected.duration === item.value}"
          @click="changeDuration(item.value)"
      >{{ item.name }}
      </li>
      <li class="js-modal-link" data-target="#modal-choose-duration"
          :class="{ 'act' : selected.duration === 'another'}"
          @click="changeDuration('another')"
      >{{ $t('modal.duration.another') }}<span v-show="anotherValue !== null">: {{anotherValue | date }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
  import {eventBus} from '@/main.js';
  import modal from '../assets/js/init/modal';

  export default {
    name: 'ChooseDuration',
    props: {
      itsFor: {
        type: String
      }
    },
    data() {
      return {
        selected: {
          duration: null,
        },
        duration: [
          {
            value: 90,
            name: this.$t('modal.duration.3-months')
          },
          {
            value: 14,
            name: this.$t('modal.duration.14-days')
          },
          {
            value: 7,
            name: this.$t('modal.duration.7-days')
          },
          {
            value: 2,
            name: this.$t('modal.duration.2-days')
          },
          {
            value: 1,
            name: this.$t('modal.duration.1-day')
          },
        ],
        anotherValue: null,
      };
    },
    created() {
      if(this.$route.params.goods) {
        let date = this.$route.params.goods.expireDate;
        if (date !== undefined && date !== null) {
          let currentDate = new Date();
          let expDate = new Date(date);
          if (currentDate < expDate) {
            this.selected.duration = 'another';
            this.anotherValue = this.$route.params.goods.expireDate;
          }
        }
      }
    },
    mounted() {
      eventBus.$on("onDateChose", date => {
        this.anotherValue = date;
        this.$emit('onChangeExpireDate', date);
      });
      eventBus.$on("onCalendarModalClose", () => {
        if(this.anotherValue === null) {
          this.selected.duration = null
        }
      });
    },
    methods: {
      changeDuration(value) {
        this.selected.duration = value;
        if(value !== 'another') {
          this.anotherValue = null;
          this.$emit('onChangeExpireDate', this.selected.duration === null ? this.selected.duration : this.addDaysToExpireDate(this.selected.duration));
        } else {
          modal.openModal('modal-choose-duration');
        }
      },
      addDaysToExpireDate(days) {
        let result = new Date();
        result.setDate(result.getDate() + days);
        return result;
      }
    }
  };
</script>

<style scoped>

</style>
