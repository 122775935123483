<template>
  <div class="section" id="price">
    <h2>{{ $t('modal.price.karmitt-price') }}</h2>
    <input type="hidden" name="type" id="input-price" value="">

    <ul>
      <li v-for="item in price" :key="item.value"
          data-val="item.value" :class="{'act' : selected.price === item.value }" @click="changePrice(item.value)">
        {{ item.name }}
        <img src="../../public/images/ico_karmitt2.svg" :alt="$t('modal.price.price')" :title="$t('modal.price.price')"
             class="svg" :class="{'hollow' : selected.price === item.value}"></li>
      <li class="js-modal-link" data-target="#modal-choose-price"
          :class="{ 'act' : selected.price === 'another'}"
          @click="changePrice('another')"
      >{{ $t('modal.price.another') }}
        <span v-show="anotherValue !== null">: {{anotherValue}}
          <img src="../../public/images/ico_karmitt2.svg" :alt="$t('modal.price.price')" :title="$t('modal.price.price')"
               class="svg hollow">
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
  import {eventBus} from '@/main.js';

  export default {
    name: 'ChoosePrice',
    data() {
      return {
        selected: {
          price: 1,
        },
        price: [
          {
            value: 1,
            name: '1'
          },
          {
            value: 10,
            name: '10'
          },
          {
            value: 50,
            name: '50'
          },
          {
            value: 100,
            name: '100'
          },
        ],
        anotherValue: null,
      }
    },
    mounted() {
      eventBus.$on("onPriceChose", price => {
        this.selected.price = 'another';
        this.anotherValue = price;
        this.$emit('onChangePrice', price);
      });
      eventBus.$on("onPriceModalClose", () => {
        if(this.anotherValue === null) {
          this.selected.price = this.price[0].value;
        }
        this.$emit('onChangePrice', this.selected.price);
      });
      this.changePrice(this.selected.price)
    },
    methods: {
      changePrice(value) {
        this.selected.price = value;
        if(this.selected.price !== 'another') {
          this.anotherValue = null;
          this.$emit('onChangePrice', value);
        }
      },
    }
  };
</script>

<style scoped>
  .hollow {
    filter: brightness(0) invert(1);
  }
</style>
