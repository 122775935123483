<template>
  <main>
    <div class="holder">
      <a href="#" id="backlink">{{ $t('common.back') }}</a>

      <section class="form full">
        <h1>{{ $t('add-specials.add-special') }}</h1>

        <form action="#" method="post" onsubmit="return false;">
          <div class="section" id="type">
            <h2>{{ $t('add-specials.type') }}</h2>
            <input type="hidden" name="type" id="input-type" value="">

            <ul>
              <li data-val="1" :class="{'act' : special.type === 'sale'}" data-toggle="discount" @click="onTypeSelected('sale')">{{ $t('add-specials.discount') }}</li>
              <li data-val="2" :class="{'act' : special.type === 'other'}" data-toggle="other" @click="onTypeSelected('other')">{{ $t('add-specials.another') }}</li>
            </ul>
          </div>

          <choose-discount @onChangeDiscount="sale => this.special.sale = sale"/>
          <choose-price @onChangePrice="cost => this.special.cost = cost"/>

          <choose-duration :its-for="'special'" @onChangeExpireDate="setExpireDate"/>

          <div class="section">
            <h2>{{ $t('add-specials.title') }}</h2>
            <input type="text" name="title" :placeholder="$t('add-specials.enter-title')" v-model="special.title" maxlength="255">
          </div>

          <div class="section">
            <h2>{{ $t('add-specials.description') }}</h2>
            <input type="text" name="title" :placeholder="$t('add-specials.enter-description')" v-model="special.description" maxlength="4000">
          </div>

          <button name="submit_btn" :disabled="!special.title.length" class="btn" @click="onSpecialSaveClicked">{{ $t('add-specials.add') }}</button>
        </form>
      </section>
    </div>
  </main>
</template>

<script>
  import modal from '../assets/js/init/modal';
  import calendar from '../assets/js/init/calendar';
  import ChooseDiscount from '../components/ChooseDiscount';
  import settings from '../assets/js/settings';
  import ChoosePrice from '../components/ChoosePrice';
  import ChooseDuration from '../components/ChooseDuration';
  import {eventBus} from '@/main.js';
  import {mapActions} from 'vuex';

  export default {
    name: 'AddSpecial',
    components: { ChooseDuration, ChoosePrice, ChooseDiscount },
    title: 'Add Special',
    data () {
      return {
        special: {
          partnerId: '',
          title: '',
          type: 'sale',
          sale: null,
          cost: null,
          expireDate: null,
          description: null
        }
      }
    },
    mounted() {
      modal.init();
      this.toggleTypeInit();
      eventBus.$on('onCalendarModalClose', () => this.special.expireDate = null)
      eventBus.$on('onChangeDiscount', sale => this.special.sale = sale)
      eventBus.$on('onChangePrice', cost => this.special.cost = cost)
    },
    methods: {
      ...mapActions(['saveSpecial']),
      onTypeSelected(type) {
        this.special.type = type;
      },
      setExpireDate(value) {
        this.special.expireDate = value;
      },
      toggleTypeInit() {
        if ($('#type').length) {
          $('#type ul li').click(function() {
            if ($(this).attr('data-toggle') == 'discount') $('#discount').stop().slideDown(settings.__animationSpeed);
            else $('#discount').stop().slideUp(settings.__animationSpeed);
          });
        }
      },
      onSpecialSaveClicked () {
        this.saveSpecial(this.special)
          .then(() => this.showToast(this.$t('toast.special-added')))
          .catch(error => this.showToast(error.response.data.message, 'error'));
      }
    }
  };
</script>

<style scoped>

</style>
